import React from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';

import Layout from '../components/layout';

class Enterprise extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ''
    };
  }

  componentDidMount() {}

  submitForm(e) {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    axios
      .post(form.action, data)
      .then(() => {
        form.reset();
        this.setState({ status: 'SUCCESS' });
      })
      .catch(() => {
        this.setState({ status: 'ERROR' });
      });
  }

  render() {
    const { status } = this.state;

    return (
      <Layout>
        <div className="skipScroll">
          <Helmet
            title={'Enterprise Blockchain | temtum'}
            meta={[
              {
                name: 'description',
                content: 'temtum (TEM) is a market leading cryptocurrency, founded by Richard Dennis, developed on the Temporal Blockchain from Dragon Infosec.'
              }
            ]}
          />
          <div className="hd-space"/>
          <div className="page">
            <section className="text-white skipScroll bg2X bg-research">
              <div className="darker video-overlay"/>
              <div className="wow3 video-overlay"/>
              <div className="container">
                <div className="row" data-aos="fade-in" data-aos-duration="1200">
                  <div className="col col-12 col-sm-6 col-md-6">
                    <h3 className="display-2 font-weight-normal mb-3 headline red pb-3">Enterprise Blockchain</h3>
                    <p className="lead">
                      The Next Wave of Digital Business.
                    </p>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6">
                    <p className="lead">
                      Prepare for the transformational impact of blockchain, from fully traceable supply chains to immutable identity management, with our portfolio of fully connected, smart blockchain solutions.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-white text-dark skipScroll pb-0">
              <div className="container">
                <div className="row" data-aos="fade-in" data-aos-duration="1200">
                  <div className="col-12 col-md-6 mx-auto">
                    <div className="text-center">
                      <img width="65px" src={require('../img/bc.svg')} alt=""/>
                      <h3>Blockchain Security</h3>
                      <p>
                        Discover how blockchain can offer a secure solution to your specific needs, and establish a plan for moving forward. Our enterprise blockchain solutions provide secure, yet transparent, mechanisms for recording and verifying any type of transaction.
                      </p>
                    </div>

                    <div className="text-center mt-3">
                      <img width="65px" src={require('../img/qs.svg')} alt=""/>
                      <h3>Blockchain & AI</h3>
                      <p>
                        Advances in AI will drive new business ecosystems, while blockchain fuels the revolution behind digitization of real-world assets. Our solution portfolio is designed to help business leaders discover new horizons only reachable through the intersection of blockchain and AI.
                      </p>
                    </div>

                    <div className="text-center mt-3">
                      <img width="65px" src={require('../img/lp.svg')} alt=""/>
                      <h3>Blockchain Integration</h3>
                      <p>
                        Establish full interoperability between devices and applications in real-time, to deliver performance, reliability, scalability, and security to your enterprise while reducing development and integration time of your blockchain business applications.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mx-auto">
                    <h3 className="display-2 font-weight-normal mb-3">
                      Contact US
                    </h3>

                    {
                      status === 'SUCCESS' ?

                        <p>Thank You! Your request has been received.</p> :

                        <form
                          onSubmit={this.submitForm}
                          action="https://formspree.io/meqkewqn"
                          acceptCharset="utf-8"
                          method="post"
                          className=""
                        >
                          <div className="form-inlineX mb-3">
                            <label className="mr-5">First Name:</label>
                            <input
                              type="text"
                              name="first_name"
                              className="form-control form-control-smX"
                              id="first_name"
                              placeholder="Enter first name"
                              required
                              pattern="[^0-9]+"
                            />
                          </div>
                          <div className="form-inlineX mb-3">
                            <label className="mr-5">Last Name:</label>
                            <input
                              type="text"
                              name="last_name"
                              className="form-control form-control-smX"
                              id="last_name"
                              placeholder="Enter last name"
                              required
                              pattern="[^0-9]+"
                            />
                          </div>
                          <div className="form-inlineX my-3">
                            <label className="mr-5">Email:</label>
                            <input
                              type="email"
                              name="email"
                              className="form-control form-control-smX"
                              id="email"
                              placeholder="Enter Email"
                              required
                            />
                          </div>
                          <div className="form-inlineX my-3">
                            <label className="mr-5">Organization (optional):</label>
                            <input
                              type="text"
                              name="organization"
                              className="form-control form-control-smX"
                              id="organization"
                              placeholder="Enter Organization"
                              required
                            />
                          </div>
                          <div className="form-inlineX my-3">
                            <label className="mr-5">Message (optional):</label>
                            <input
                              type="text"
                              name="form_message"
                              className="form-control form-control-smX"
                              id="form_message"
                              placeholder="Enter Message"
                              required
                            />
                          </div>
                          <input
                            type="submit"
                            className="btn btn-secondary mb-2"
                            value="Send"
                          />
                        </form>
                    }
                    {
                      status === 'ERROR' && <p>Ooops! There was an error.</p>
                    }
                  </div>
                </div>
              </div>
            </section>
            <section className="bg-white border-top skipScroll mt-5">
              <div className="container">
                <div className="row" data-aos="fade-in" data-aos-duration="1200">
                  <div className="col col-12 mx-auto text-center">
                    <h3 className="display-3 font-weight-bold mb-3">Our Partners</h3>
                    <p>
                      Establish interoperability between blockchain platforms and seamlessly facilitate asset transfer between platforms, all while maintaining uniqueness and immutability.
                    </p>
                  </div>
                  <div className="col col-12 col-sm-12 col-md-6 mb-3 text-center plain-cards">
                    <div className="card card-body py-5">
                      <img style={{height: '65px'}} src={require('../img/partners/logo-hyperledger.png')} alt=""/>
                      <h4 className="card-title display-4 font-weight-bold">Hyperledger</h4>
                      <p className="card-text">
                        The cross-industry blockchain technology, accelerating outcomes for leaders in finance, banking, supply chains, manufacturing, and more.
                      </p>
                    </div>
                  </div>

                  <div className="col col-12 col-sm-12 col-md-6 mb-3 text-center plain-cards">
                    <div className="card card-body py-5">
                      <img style={{height: '65px'}} src={require('../img/partners/logo-eos.png')} alt=""/>
                      <h4 className="card-title display-4 font-weight-bold">EOS</h4>
                      <p className="card-text">
                        EOS, the highly performant distributed ledger technology that is being used to unleash innovation across the enterprise.
                      </p>
                    </div>
                  </div>

                  <div className="col col-12 col-sm-12 col-md-6 mb-3 text-center plain-cards">
                    <div className="card card-body py-5">
                      <img style={{height: '65px'}} src={require('../img/partners/logo-ethereum.png')} alt=""/>
                      <h4 className="card-title display-4 font-weight-bold">Ethereum</h4>
                      <p className="card-text">
                        Our extensive experience and expertise positions us as the development partner of choice for organizations of all sizes, from the local community to the global enterprise, with Ethereum.
                      </p>
                    </div>
                  </div>

                  <div className="col col-12 col-sm-12 col-md-6 mb-3 text-center plain-cards">
                    <div className="card card-body py-5">
                      <img style={{height: '65px'}} src={require('../img/partners/logo-stellar.png')} alt=""/>
                      <h4 className="card-title display-4 font-weight-bold">Stellar</h4>
                      <p className="card-text">
                        The Stellar blockchain platform connects banks, payment systems, and people. Our engineers leverage this powerful platform to build industry-leading applications for enterprise clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Enterprise;
